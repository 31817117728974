import TabBar from '../Tabbar';
import FilterSection from './Filters';
import './Landing.css'
import MainContent from './MainContent';
import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { getDocs, collection, query, orderBy} from 'firebase/firestore';
import { SyncLoader, RiseLoader, PropagateLoader} from 'react-spinners';
import { useLanguage } from '../LanguageToggle/LanguageProvider';
import { useHistory } from 'react-router-dom';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

function LandingPage() {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  // const itemsPerPage = 12;
  // const [cardsToShow, setCardsToShow] = useState(itemsPerPage);
  const [cardsData, setCardsData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [filterLoading, setFilterLoading] = useState(false);
  const [selecteDate, setSelectedDate] = useState(null);
  const { currentLanguage, handleLanguageChange } = useLanguage();
  const [allCardsData, setAllCardsData] = useState([]);
  const [categoryMapping, setCategoryMapping] = useState({});
  const { t} = useTranslation()

  
  const handleLocalLanguageChange = (language) => {
    handleLanguageChange(language);
  };

   const handleSelectDate = (date) => { 
    setSelectedDate(date)
   }

    const handleClearCategory = () => {
      setSelectedCategory(null);
    };
  
    
    const handleCategorySelect = (category) => {
      setSelectedCategory(category);
    };
  
    const handleCitySelect = (city) => {
      setSelectedCity(city);
    };


    const getCategoriesList = (categoriesSnapshot) => {
      const mapping = {};
      categoriesSnapshot.docs.forEach((doc) => {
        const data = doc.data();
        mapping[data.chinese_name] = data.name;
      });
    
      setCategoryMapping(mapping);  // Update categoryMapping state
    
      if (currentLanguage === 'zh') {
        return Object.keys(mapping);
      } else {
        return Object.values(mapping);
      }
    };
    
    useEffect(() => {
        
      const fetchCategoriesAndCities = async () => {
        const categoriesSnapshot = await getCategories();
        const citiesSnapshot = await getCities();
      
        const categoriesList = getCategoriesList(categoriesSnapshot);
        const citiesList = citiesSnapshot.docs.map((doc) => doc.data().name);

        setCategories(categoriesList);
        setCities(citiesList);
      
        saveDataToLocale('cachedCategories', categoriesList);
        saveDataToLocale('cachedCities', citiesList);
      
        setIsLoading(false);
      };
        
        fetchCategoriesAndCities();

      }, [currentLanguage]);
    
      // Function to save data to local storage
      const saveDataToLocale = (key, data) => {
        localStorage.setItem(key, JSON.stringify(data));
      };
  
      const getCategories = async () => {
        const q = query(collection(db, 'categories'), orderBy('name'));
        return await getDocs(q);
      };
    
      const getCities = async () => {
        const q = query(collection(db, 'cities'), orderBy('name'));
        return await getDocs(q);
      };


      useEffect(() => {
        const fetchAllVendors = async () => {
          setFilterLoading(true);
    
          const vendorRef = collection(db, currentLanguage === 'en' ? 'vendorenglish' : 'vendorchinese');
          const q = query(vendorRef, orderBy('vendor_name'));
    
          const vendorSnapshot = await getDocs(q);
          const vendorList = vendorSnapshot.docs.map((doc) => {
            const data = doc.data();
            const extractedData = {
              id: doc.id,
              title: data.title,
              vendor_cover_image: data.vendor_cover_image,
              user_avatar: data.user_avatar,
              vendor_name: data.vendor_name,
              user_name: data.user_name,
              categories: data.categories,
              cities: data.cities,
              daysOpen: data.daysOpen,
              // Add more fields if necessary
            };
            return extractedData;
          });
    
          // Save fetched data to local storage
          saveDataToLocal('cachedVendors', vendorList);
          setFilterLoading(false);
    
          setAllCardsData(vendorList);
          setCardsData(vendorList);
        };
    
        fetchAllVendors();
      }, [currentLanguage]);
      // Function to save data to local storage
    const saveDataToLocal = (key, data) => {
      localStorage.setItem(key, JSON.stringify(data));
    };

    useEffect(() => {
      const applyAdditionalFilters = () => {
        let filteredVendors = allCardsData;
      
        if (selecteDate) {
          filteredVendors = filteredVendors.filter((vendor) =>
            vendor.daysOpen.includes(selecteDate.dayIndex.toString())
          );
        }
        
        
        if (selectedCategory && selectedCategory !== 'All') {
          if(currentLanguage === 'zh') {
          const englishCategory = categoryMapping[selectedCategory];
      
          if (englishCategory) {
            filteredVendors = filteredVendors.filter((vendor) =>
              vendor.categories.includes(englishCategory)
            );
          } else {
            console.warn("category not found for:", selectedCategory);
          }
        }else { 
          filteredVendors = filteredVendors.filter((vendor) =>
            vendor.categories.includes(selectedCategory)
          );
        }
        }
      
        if (selectedCity) {
          filteredVendors = filteredVendors.filter((vendor) =>
            vendor.cities.includes(selectedCity)
          );
        }
      
        setCardsData(filteredVendors);
      };
    
      applyAdditionalFilters();
    }, [selecteDate, selectedCategory, selectedCity, categories]);
    
    

    
  
    // const loadMore = () => {
    //   setIsLoading(true);
    
    //   try {
    //     const nextBatchStartIndex = cardsToShow;
    //     const nextBatchEndIndex = nextBatchStartIndex + itemsPerPage;
    //     const nextBatchList = allCardsData.slice(nextBatchStartIndex, nextBatchEndIndex);
    
    //     setCardsData([...cardsData, ...nextBatchList]);
    //     setCardsToShow(nextBatchEndIndex);
    //   } catch (error) {
    //     console.error('Error loading more data:', error);
    //   } finally {
    //     setIsLoading(false);
    //   }
    // };

    return (
        <div className='landing-page'>
            {isLoading ? ( 
        <div className="loading-container">
        <p className="loading-text">Delicious is coming...</p>
        <SyncLoader
          color='green'
          size={30}
          aria-label="Loading Spinner"
        />
      </div>
            ) : (
                <> 
                <TabBar 
                onDateSelect={handleSelectDate}
                />
                <div className='sticky-filter' style={{maxWidth: '100%', minWidth: '100%'}}>
                <FilterSection 
                categories={categories} 
                cities={cities} 
                onCategorySelect={handleCategorySelect} 
                onCitySelect={handleCitySelect} 
                selectedDate={selecteDate ? selecteDate : null}

                />
                </div>
    
                <div className='container' style={{maxWidth: '1140px'}}>
                  {filterLoading ? ( 
                <div className="loading-container">
                  <p className="loading-text">Loading...</p>
                  <PropagateLoader
                    color='green'
                    size={20}
                    aria-label="Loading Spinner"
                  />
                </div>
                  ) : ( 
                <MainContent 
                // itemPerPage={itemsPerPage} 
                // cardsToShow={cardsToShow} 
                cardsData={cardsData} 
                // loadMore={loadMore} 
                selectedCategory={selectedCategory ? selectedCategory : null}
                selectedCity={selectedCity ? selectedCity : null}
                handleClearCategory={handleClearCategory}  // Pass the handleClearCategory function as a prop
                />
                  )}
                </div>
                  
                             
    
                {/* <Footer /> */}
                </>
            )}
            
        </div>
    )
}

export default LandingPage;
