import React, { useState } from 'react';
import TabBar from '.';
import { useHistory } from 'react-router-dom';
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';
import { set } from 'firebase/database';


const ChefRegistrationForm = () => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [city, setCity] = useState('');
  const [cuisineType, setCuisineType] = useState('');
  const [license, setLicense] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const history = useHistory();


  const handleSubmit = async () => {
    // Validate input if needed
    if(!name || !phoneNumber || !city) {
      alert('Please fill out all required fields.');
      return;
    }


    // Perform any additional actions
    const templateParams = {
      name: name,
      phoneNumber: phoneNumber,
      email: email,
      city: city,
      cuisineType: cuisineType,
      license: license,
    };


    // Replace these values with your emailjs template and user ID
    const serviceId = 'service_ufrr2h8';
    const templateId = 'template_9t7ofql';
    const userId = 'qAd3HK1wqeVSxsppX';


    emailjs.send(serviceId, templateId, templateParams, userId)
      .then(() => {
        setFormSubmitted(true);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });


    setFormSubmitted(true);
  };


  return (
    <>
      <TabBar />
      <div className='chef-registration-page'>
          <div className='container'>
            <div className='row justify-content-center'>
          {!formSubmitted ? (
              <div className='col-md-8 text-center mt-5'>
                <h1>{t('h1-signup')}</h1>
                <p>{t('p-signup')}</p>


                <div className='cards input'>
            <>
              <form>
                <div className="row mb-3">
                  <div className="col-md-4">
                    <input
                      name='name'
                      type="text"
                      className="form-control"
                      placeholder={t('placeholders.name')}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="col-md-4">
                    <input
                      name='phoneNumber'
                      type="text"
                      className="form-control"
                      placeholder={t('placeholders.phoneNumber')}
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      required
                    />
                  </div>
                  <div className="col-md-4">
                    <input
                      name='email'
                      type="text"
                      className="form-control"
                      placeholder={t('placeholders.email')}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-4">
                    <input
                      name='city'
                      type="text"
                      className="form-control"
                      placeholder={t('placeholders.city')}
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      required
                    />
                  </div>
                  <div className="col-md-4">
                    <input
                      name='cuisineType'
                      type="text"
                      className="form-control"
                      placeholder={t('placeholders.cuisineType')}
                      value={cuisineType}
                      onChange={(e) => setCuisineType(e.target.value)}
                    />
                  </div>
                  <div className="col-md-4">
                    <input
                      name='license'
                      type="text"
                      className="form-control"
                      placeholder={t('placeholders.license')}
                      value={license}
                      onChange={(e) => setLicense(e.target.value)}
                    />
                  </div>
                </div>
                <button type="button" className="button-37" onClick={handleSubmit}>
                  {t('buttonText')}
                </button>
              </form>
            </>
                </div>
              </div>
          ) : (
            <div className="text-center">
              <h3 className="mb-4">{t('formSubmittedTitle')}</h3>
              <p>{t('formSubmittedText')}</p>
              <button type="button" className="button-37" onClick={() => history.push('/')}>
                {t('formSubmittedButtonText')}
              </button>
            </div>
          )}
          </div>
        </div>
      </div>
    </>
  );
};


export default ChefRegistrationForm;
