// ModalToggleContext.js

import React, { createContext, useContext, useState } from 'react';

const ModalToggleContext = createContext();

export const useModalToggle = () => {
  return useContext(ModalToggleContext);
};

export const ModalToggleProvider = ({ children }) => {
  const [showModal, setShowModal] = useState(false);

  const handleModalToggle = () => {
    setShowModal((prevShowModal) => !prevShowModal);
  };

  return (
    <ModalToggleContext.Provider value={{ showModal, handleModalToggle }}>
      {children}
    </ModalToggleContext.Provider>
  );
};
