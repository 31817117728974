import React, { useState, useRef, useEffect } from 'react';
import './Footer.css'; // Assuming you have a Footer.css file for custom styling
import { set, onValue, ref } from 'firebase/database'
import { rdb } from '../../firebase';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function Footer() {
  const [logo, setLogo] = useState('')
  const { t } = useTranslation();
  const history = useHistory()

  useEffect(() => { 
    const logoRef = ref(rdb, 'logo')
    onValue(logoRef, snapShot => { 
      const data= snapShot.val();
      setLogo(data || '')
    })
  }, [])

  return (
    <footer className="bg-white py-4 container-fluid">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 text-center text-md-left"> 
            <img className='footer-logo img-fluid' src={logo?.logo} alt="Placeholder Logo" />
          </div>
          <div className="col-md-6 text-center text-md-right">
            <div className="row">
              <div className="col-md-12">
                <h5>Chefhubs</h5>
                <p style={{cursor:'pointer'}} onClick={() => history.push('/terms')}>{t('aboutText')}</p>
                <p style={{cursor:'pointer'}} onClick={() => history.push('/privacy')} >{t('contactText')}</p>
              </div>
              {/* <div className="col-md-6">
                <h5 >{t('connectWithUsText')}</h5>
                <p>Facebook</p>
                <p>Twitter</p>
              </div> */}
            </div>
          </div>
        </div>


      </div>
        <div className="row mt-3">
          <div className="col-md-12 text-center">
            <hr className="my-1" />
            <p>&copy; {new Date().getFullYear()} Chefhubs. All Rights Reserved.</p>
          </div>
        </div>

    </footer>
  );
}

export default Footer;
