import React, { useEffect, useState } from 'react';
import CardContent from './CardContent';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PuffLoader } from 'react-spinners';

function MainContent({ cardsData, selectedCategory }/*{ cardsToShow, loadMore, }*/) {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div style={{ minHeight: '100vh' }}>
      <h1 className="main-title mt-3">
        {selectedCategory ? selectedCategory + ' ' + t('chefText') : t('AllChefText')}
      </h1>
      <div>
        {cardsData.length === 0 ? (
          <div className="loading-container">
            <p className="loading-text">{t('noChefText')}</p>
            <PuffLoader
              color='green'
              size={150}
              aria-label="Loading Spinner"
            />
          </div>
        ) : (
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
            {cardsData.map((card) => (
              <div key={card.id} className="col mb-3" onClick={() => history.push(`/vendor/${card.id}`)}>
                <CardContent card={card} />
              </div>
            ))}
          </div>
        )}
      </div>
      {/* {cardsToShow <= cardsData.length && (
        <div className="text-center mt-2">
          <button className="button-6 mb-3" onClick={loadMore}>
            Load More
          </button>
        </div>
      )} */}
    </div>
  );
}

export default MainContent;
