import React, { useState, useRef, useEffect } from 'react';
import Avatar from 'react-avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

import './VendorDetail.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';

function VendorDetail({vendor, dayOpen}) {

  const history = useHistory()
  const [showFullDescription, setShowFullDescription] = useState(false);
  const datesContainerRef = useRef(null);
  const { t} = useTranslation();
  const [dates, setDates] = useState([]);
  
  const scrollDates = (direction) => {
    if (datesContainerRef.current) {
      const container = datesContainerRef.current;
      const scrollOffset = 200; // Adjust this value to your desired scroll distance
      if (direction === 'left') {
        container.scrollBy({
          top: 0,
          left: -scrollOffset,
          behavior: 'smooth',
        });
      } else if (direction === 'right') {
        container.scrollBy({
          top: 0,
          left: scrollOffset,
          behavior: 'smooth',
        });
      }
    }
  };


  useEffect(() => { 
    const getTwoWeeksDates =  () => {
      const today = new Date();
      const dates = [];
    
      // Use vendor.daysOpen to define the days that you want to include
      const includedDays = new Set(vendor?.daysOpen?.map(Number));
    
      for (let i = 0; dates.length < 14; i++) {
        const nextDay = new Date(today);
        nextDay.setDate(today.getDate() + i);
    
        const dayOfWeek = nextDay.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
    
        if (includedDays.has(dayOfWeek)) {
          const day = nextDay.getDate();
          const isToday = i === 0;
          
          const dateInfo = {
            day: nextDay.toLocaleString('default', { weekday: 'short' }),
            date: `${nextDay.toLocaleString('default', { month: 'short' })} ${day}`,
            isToday: isToday,
          };
          dates.push(dateInfo);
        }
      }
  
      setDates(dates);
    };
      
    if (vendor?.daysOpen?.length > 0) {
      getTwoWeeksDates();
    }
  }, []);
  
  

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };


    return (
        <div className="container mt-3">
            {/* Banner Image */}
            <div className="row">
        <div className="col-12 position-relative">
        <button
            className="back-button button-68"
            onClick={() => {
              history.push('/home')
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft} /> {t('backText')}
          </button>
          <img src={vendor?.vendor_cover_image} alt="Vendor Banner" className="vendor_cover_image" />
          <div className=" d-md-none position-absolute  bottom-0 ">
            <Avatar src={vendor?.user_avatar} size="110" round={true} />
          </div>
        </div>
      </div>

      {/* User Icon, Name, Category, and Description */}
      <div className="row mt-3">

        <div className="col-md-9 content-margin">
          <h2 className="text-title">{vendor?.vendor_name} {t('menuText')}</h2>
          <div style={{ display: 'flex', alignItems: 'center' }}>
  {vendor?.categories?.map((cat, index) => (
    <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
      <p className='text-content' style={{ marginRight: '5px' }}><i className="fa-solid fa-circle-dot" style={{fontSize: '15px', color:'#2d6734'}}/>{cat}</p>
    </div>
  ))}
  
</div>
          <p className="text-fee">${vendor?.delivery_fee} {t('deliverFeeText')}</p>
          <p className="text-description">
            {showFullDescription ? vendor?.description : `${vendor?.description.slice(0, 150)}`}
            {!showFullDescription && (
                <div>
              <p style={{color:'#2d6734', cursor:'pointer', }} onClick={() => toggleDescription()}>{t('learnMoreText')}</p>
              </div>
            )}
          </p>
        </div>

        <div className="col-3 mb-3 d-none d-md-block">
          <Avatar className="margin-avatar" src={vendor?.user_avatar} size="160" round={true} />
        </div>

      </div>

                <div className="row mt-3">
                <div className="col-md-12">
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {vendor?.cities && vendor.cities.length > 0 ? (
                vendor.cities.map((city, index) => (
                    <div key={index} style={{ marginRight: '10px' }}>
                        <FontAwesomeIcon icon={faMapMarkerAlt} /> {city}
                    </div>
                ))
            ) : (
                <p>No cities available for this vendor</p>
            )}
        </div>
    </div>
                <div className="col-md-12">
                    <h4>{t('SelectdeliveryDateText')}</h4>
                    <div className="date-row-main">
                        {/* Scroll buttons */}
                        <button className="scroll-button" onClick={() => scrollDates('left')}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        <div className="scroll-view" ref={datesContainerRef}>
                            {dates?.map((dayData, index) => (
                                <div
                                className="date-box"
                                 
                                    
                                >
                                    <div className="day">{dayData.day}</div>
                                    <div className="date">{dayData.date}</div>
                                </div>
                            ))}
                        </div>
                        <button className="scroll-button" onClick={() => scrollDates('right')}>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                    </div>
                </div>
            </div>

                              

        </div>
    );
}

export default VendorDetail;
