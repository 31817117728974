import React from 'react';
import './VendorDetail.css'; // Assuming you have a Menu.css file for custom styling
import { useLanguage } from '../LanguageToggle/LanguageProvider';

function Menu({vendor}) {
  const { currentLanguage } = useLanguage();


  return (
    <div className='container mt-3' style={{ maxWidth: '1140px' }}>
      <div className='container'>
        <div className="row custom-row">
          {vendor?.menu.map((item) => (
            <div key={item.id} className="col-lg-4 col-md-6 col-sm-12 mb-4 mt-3">
              <div className="card">
                <img src={item.image} className="menu-image" alt={`Image of ${item.name}`} />
                <div className="card-body">
                  <h5 className="card-title">{currentLanguage === 'en' ? item?.dishName : item?.chineseDishName}</h5>
                  <p className="card-text">${item?.price}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Menu;
