import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import './TabBar.css'; // Import the associated CSS file
import { useModalToggle } from '../LandingPage/ParentCom'; // Import the context function
import { useHistory } from 'react-router-dom';
import { rdb } from '../../firebase';
import { set, onValue, ref } from 'firebase/database'
import { useTranslation } from 'react-i18next';
import LanguageToggle from '../LanguageToggle';
import { useLanguage } from '../LanguageToggle/LanguageProvider';
import logoImg from './png.png'


function TabBar( {onDateSelect}) {
  const datesContainerRef = useRef(null);
  const modalRef = useRef(null);
  const { showModal, handleModalToggle } = useModalToggle(); // Get the context function
  const history = useHistory();
  const [slogan, setSlogan] = useState('')
  const [selectedDate, setSelectedDate] = useState('')
  const [logo, setLogo] = useState('')
  const {t} = useTranslation();
  const { currentLanguage, handleLanguageChange } = useLanguage();

  // ...
  
  const handleLocalLanguageChange = (language) => {
    handleLanguageChange(language);
  };

  
  const handleSelectDate = (date) => { 
    setSelectedDate(date)
    onDateSelect(date)
    closeModal()
  }


  const closeModal = () => { 
    handleModalToggle();
  }

  
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    const sloganRef = ref(rdb, currentLanguage === 'zh' ? 'chineseSlogan' : 'slogan');
    onValue(sloganRef, (snapshot) => {
      const data = snapshot.val();
      setSlogan(data || ''); // Use an empty string as a default value
    });
  }, [currentLanguage]);
  
  useEffect(() => { 
    const logoRef = ref(rdb, 'logo')
    onValue(logoRef, snapShot => { 
      const data= snapShot.val();
      setLogo(data || '')
    })
  }, [])


  useEffect(() => {
    if (showModal) {
      document.body.classList.add('modal-open');
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.body.classList.remove('modal-open');
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showModal]);



  const scrollDates = (direction) => {
    if (datesContainerRef.current) {
      const container = datesContainerRef.current;
      const scrollOffset = 200; // Adjust this value to your desired scroll distance
      if (direction === 'left') {
        container.scrollBy({
          top: 0,
          left: -scrollOffset,
          behavior: 'smooth',
        });
      } else if (direction === 'right') {
        container.scrollBy({
          top: 0,
          left: scrollOffset,
          behavior: 'smooth',
        });
      }
    }
  };




  return (
    <div className='tab-bar sticky-top '>
      {/* Main Navbar */}
      <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top ">
        <div className="container-fluid">
          <div className="navbar-brand" onClick={() => history.push('/')}>
            <img src={logo?.logo ? logo?.logo : logoImg} alt="Logo" className="logo" style={{cursor:'pointer'}} />
          </div>
          <div className='slogan-container'>
            <p style={{color: 'rgb(10, 100, 10)'}}>{slogan}</p>
          </div>

          <div className="lan-container">
          <LanguageToggle onChangeLanguage={handleLanguageChange} />

          <div className="justify-content-end">
            <button onClick={() => history.push('/chef/signup')}  className="button-41">{t('signUpText')}</button>
          </div>
          </div>
        </div>
      </nav>

      {/* Navbar Modal */}
      {showModal && (
        <div className="modal-container">
          <div className="modal-content" ref={modalRef}>
            {/* Background overlay */}

            {/* Scroll arrows */}
            <div className="scroll-arrows">
              <button onClick={() => scrollDates('left')}  className='hide-arrow'>
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
              <button onClick={() => scrollDates('right')} className='hide-arrow'>
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
            
            {/* Dates container */}
            <div className="date-row">
              <div className="scroll-view" ref={datesContainerRef}>
                {getTwoWeeksDates().map((dayData, index) => (
                  <div className="date-box" key={index}  onClick={() => handleSelectDate(dayData)}>
                    <div className="day" >{dayData.day}</div>
                    <div className="date">{dayData.date}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      {showModal && <div className="overlay" onClick={closeModal}></div> }

    </div>
  );
}

export const getTwoWeeksDates = () => {
  const today = new Date();
  const dates = [];
  for (let i = 0; i < 28; i++) {
    const nextDay = new Date(today);
    nextDay.setDate(today.getDate() + i);
    const day = nextDay.getDate();
    const dayIndex = nextDay.getDay(); // Numerical representation of the day (0 for Sunday, 1 for Monday, etc.)

    if (i === 0) {
      dates.push({
        day: 'Today',
        date: day,
        dayIndex: dayIndex, // Adding 1 to match your database representation (1 for Monday, 2 for Tuesday, etc.)
      });
    } else {
      const month = nextDay.toLocaleString('default', { month: 'short' });
      const weekday = nextDay.toLocaleString('default', { weekday: 'short' });
      dates.push({
        day: weekday,
        date: `${month} ${day}`,
        dayIndex: dayIndex, // Adding 1 to match your database representation (1 for Monday, 2 for Tuesday, etc.)
      });
    }
  }
  return dates;
};


export default TabBar;
