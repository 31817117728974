import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import './LanguageToggle.css';  // Import the associated CSS file for styling

const LanguageToggle = ({ onChangeLanguage }) => {
  const { i18n } = useTranslation();

  const toggleLanguage = () => {
    const newLanguage = i18n.language === 'zh' ? 'en' : 'zh';
    i18n.changeLanguage(newLanguage);
    onChangeLanguage(newLanguage);
  };

  return (
    <button className="language-toggle" onClick={toggleLanguage}>
      <FontAwesomeIcon icon={faLanguage} className="language-icon" />
      <span className="language-text">
        {i18n.language === 'en' ? 'Eng' : '中文'}
      </span>
    </button>
  );
};

export default LanguageToggle;
