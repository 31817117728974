import React from 'react';
import Avatar from 'react-avatar';
import './Landing.css'; // Import your CSS file for CardContent styling

function CardContent({card}) {
  
  return (
    <div className="card rounded-4 card-custom">
      <div className="image-container">
        <img src={card?.vendor_cover_image} className="card-img-top" alt="Sample" />
        <div className="avatar-wrapper">
          <Avatar src={card?.user_avatar} size="80" round={true} />
        </div>
      </div>
      <div className="card-body">
        <h5 className="card-title mb-1">{card?.vendor_name}</h5>
        <p className="card-text text-muted mb-1">{card?.user_name}</p>
      </div>
    </div>
  );
}

export default CardContent;
