import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import './Landing.css';
import { useModalToggle } from './ParentCom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

function FilterSection({ categories, cities, onCategorySelect, onCitySelect, selectedDate }) { 
    const containerRef = useRef(null);
    const { handleModalToggle } = useModalToggle();
    const [showCitiesModal, setShowCitiesModal] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const { t } = useTranslation();
    const history = useHistory();

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
  
    // Function to handle category selection
    const handleCategorySelection = (category) => {
      setSelectedCategory(category);
      onCategorySelect(category); // Pass the selected category to the parent component (LandingPage)
    };
  
    // Function to handle city selection
    const handleCitySelection = (city) => {
      setSelectedCity(city);
      onCitySelect(city); // Pass the selected city to the parent component (LandingPage)
      setShowCitiesModal(false); // Close the modal
    };
  


    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 10) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    // ... (rest of your component code)
  
    const dynamicStyles = {
      borderBottom: scrolled ? '2px solid #d8d8d8' : 'none',
      borderRadius: scrolled ? '1px' : 'none',
    };
  
    const scroll = (scrollOffset) => {
      const container = containerRef.current;
      container.scrollLeft += scrollOffset;
    };

    useEffect(() => {
      const handleCloseModal = (event) => {
        if (showCitiesModal && event.target.classList.contains('modal')) {
          setShowCitiesModal(false);
        }
      };
  
      document.addEventListener('click', handleCloseModal);
  
      return () => {
        document.removeEventListener('click', handleCloseModal);
      };
    }, [showCitiesModal]);
  
    const scrollDistance = 300;
  

      const handleCityButtonClick = () => {
        setShowCitiesModal(true);
      };
  
      const closeModal = () => {
        setShowCitiesModal(false);
      };

      const categoriesWithAll = [t('AllText'), ...categories];

      return (
        <div className={`container sticky-top mt-4 mb-2 ${showCitiesModal ? 'modal-open' : ''}`} style={dynamicStyles}>
        <div className="row justify-content-center">
            <div className="col-md-10 d-flex justify-content-center">
            <FontAwesomeIcon className='faleft' onClick={() => {history.push('/')}} icon={faChevronLeft} />

              <div className="date-filter-header" onClick={handleModalToggle}>
                <span className="delivery-text">{t('deliverText')} </span>
                <span className="delivery-text-bold">{selectedDate?.date ?selectedDate?.day + ',' + selectedDate?.date : t('deliveryDateText')}</span>
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
              <div style={{marginLeft: '20px'}}>
                <button className="button-6" onClick={handleCityButtonClick}>{selectedCity ? selectedCity : t('cityText')}</button>
              </div>
            </div>
          </div>
            
          <div className="category-filter-container">
            <div className="arrow-scroll left-arrow" onClick={() => scroll(-scrollDistance)}>
              <FontAwesomeIcon icon={faAngleLeft} />
            </div>
            <div className="category-scroll" ref={containerRef}>
  {categoriesWithAll.map((category, index) => (
    <div
      key={index}
      className={`category-item ${selectedCategory === category ? 'selected' : ''}`}
      onClick={() => handleCategorySelection(category)}
    >
      {category}
    </div>
  ))}
</div>
            <div className="arrow-scroll right-arrow" onClick={() => scroll(scrollDistance)}>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </div>
  
        {/* Bootstrap Modal */}
        <div
          className="modal fade show" // Bootstrap class to control visibility
          style={{ display: showCitiesModal ? 'block' : 'none' }}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="cityModalLabel"
          aria-hidden={!showCitiesModal}
        >
          <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="cityModalLabel">{t('selectCityText')}</h5>
                <button type="button" className="button-1" aria-label="Close" onClick={closeModal}>
                  {t('closeText')}
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  {cities.map((city, index) => (
            <div key={index} className="col-lg-3 col-md-4 col-sm-6 mb-2">
            <button onClick={() => handleCitySelection(city)} type="button" className="button-17">{city}</button>
          </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal backdrop with click event to close */}
        {showCitiesModal && <div className="modal-backdrop fade show" onClick={closeModal}></div>}
      </div>
      );
}

export default FilterSection;
