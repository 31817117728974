import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Switch, useLocation } from 'react-router-dom';
import Home from './components/HomePage/Home';
import LandingPage from './components/LandingPage';
import VendorMain from './components/VendorDetail/index';
import React, { useEffect } from 'react';
import ChefRegistrationForm from './components/Tabbar/ChefSignup';
import TermsAndPolicy from './components/Footer/Terms';
import PrivatePolicy from './components/Footer/Policy';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div className='app'>
      <ScrollToTop />
      <Switch>
        <Route exact path='/'>
          <Home />
        </Route>
        <Route exact path='/home'>
          <LandingPage />
        </Route>
        <Route path='/vendor/:vendorId'>
          <VendorMain />
        </Route>
        <Route path='/chef/signup'>
          <ChefRegistrationForm />
        </Route>

        <Route path='/terms'>
          <TermsAndPolicy />
        </Route>

        <Route path='/privacy'>
          <PrivatePolicy />
          </Route>
      </Switch>
    </div>
  );
}

export default App;
