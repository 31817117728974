import TabBar from "../Tabbar";
import VendorDetail from "./MainContent";
import Menu from "./Menu";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { SyncLoader, RiseLoader} from 'react-spinners';
import { useTranslation } from "react-i18next";
import { useLanguage } from "../LanguageToggle/LanguageProvider";


function VendorMain() { 
    const { vendorId } = useParams();
    const [vendor, setVendor] = useState(null);
    const [daysOpen, setDaysOpen] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const { currentLanguage, handleLanguageChange } = useLanguage();

    // ...
    
    const handleLocalLanguageChange = (language) => {
      handleLanguageChange(language);

    };    
    const { t} = useTranslation()


    window.scrollTo(0, 0); // Scroll to the top of the page on component mount or when pathname changes

    useEffect(() => {
        setIsLoading(true);
    
        const fetchVendor = async () => {
            try {
                const vendorRef = doc(db, currentLanguage === 'en' ? 'vendorenglish' : 'vendorchinese', vendorId);
                const vendorSnapshot = await getDoc(vendorRef);
    
                if (vendorSnapshot.exists()) {
                    // Exclude unwanted fields like 'phone', 'address', and 'state'
                    const { phone, address, state, email, id, zip_code, ...restOfData } = vendorSnapshot.data();
                    setVendor(restOfData);
                    setIsLoading(false);
                } else {
                    console.log("Vendor not found");
                }
            } catch (error) {
                console.error("Error fetching vendor:", error);
            }
        }
    
        fetchVendor();
    }, [vendorId, currentLanguage]);
    


    const handlePlaceOrder = () => {
        // Check if the vendor object and paymentLink property exist
        if (vendor && vendor.paymentLink) {
            // Ensure that the payment link has the correct format (e.g., "https://www.youtube.com/")
            const fullPaymentLink = vendor.paymentLink.startsWith('http') ? vendor.paymentLink : `https://${vendor.paymentLink}`;
    
            // Open the payment link in a new tab
            window.open(fullPaymentLink, '_blank');
        } else {
            console.error("Payment link not found for the vendor.");
        }
    };


    return (
        <div>
                      {isLoading ? ( 
        <div className="loading-container">
        <p className="loading-text">Delicious is coming...</p>
        <SyncLoader
          color='green'
          size={30}
          aria-label="Loading Spinner"
        />
      </div>
            ) : (
                <>
            <TabBar />

            <div className='container ' style={{maxWidth: '1140px'}}>
            <VendorDetail vendor={vendor} dayOpen={daysOpen}/>
            </div>

            <div className="bottom-half-content" style={{marginBottom:'90px'}}>
                <Menu vendor={vendor} />
            </div>

            <div className="order-button-fullwidth button-58 " onClick={handlePlaceOrder}>
                {t('placeOrderText')}
            </div>
            </>
            )}
        </div>
    );
}

export default VendorMain;
