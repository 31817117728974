import React  from 'react';
import './Home.css'; // Import the associated CSS file
import { useState, useEffect } from 'react';
import TabBar from '../Tabbar';
import Footer from '../Footer/Footer';
import { useHistory } from 'react-router-dom';
import { rdb } from '../../firebase';
import { get, ref, onValue, set } from 'firebase/database';
import Avatar from 'react-avatar';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../LanguageToggle/LanguageProvider';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Import Font Awesome icons
import { HashLoader } from 'react-spinners';

function Home() {
  const history = useHistory();
  const [Topchefs, setTopChefs] = useState([]);
  const { t } = useTranslation()
  const { currentLanguage, handleLanguageChange } = useLanguage();
  const [loading, setLoading] = useState(true);
  const [backgroundImage, setBackgroundImage] = useState(null);


  useEffect(() => {
    const getTopChefData = () => {
      const topChefRef = ref(rdb, 'topchef');
      onValue(topChefRef, (snapshot) => {
        const data = snapshot.exists() ? snapshot.val() : null;
        if (data) {
          const topChefData = Object.keys(data).map((chefId, index) => ({
            ...data[chefId],
          }));
  
          setTopChefs(topChefData);
          setLoading(false); // Set loading to false when data is fetched
        } else {
          console.error('No data found in snapshot');
        }
      }, (error) => {
        console.error('Error getting snapshot:', error);
      });
    };
  

    const getBackgroundImage = () => {
      const backgroundImageRef = ref(rdb, 'background');
      setLoading(true);
      onValue(backgroundImageRef, (snapshot) => {
        const data = snapshot.exists() ? snapshot.val() : null;
        if (data) {
          const backgroundImage = data.background;
          setBackgroundImage(backgroundImage);
          setLoading(false); // Set loading to false when data is fetched
        } else {
          console.error('No data found in snapshot');
          setLoading(false);
        }
      }
      , (error) => {
        console.error('Error getting snapshot:', error);
        setLoading(false);
      });
    };
    
    getBackgroundImage();
    getTopChefData();
  }, []);
  console.log(Topchefs)
  
  const [expandedIndex, setExpandedIndex] = useState(null);
  
  const handleToggleAnswer = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const faqData = currentLanguage === 'en' ? englishFaqData : chineseFaqData;

  
  return (
    <div>
      {loading ? (
        <div className="loading-container">
        <p className="loading-text">Delicious is coming...</p>
        <HashLoader
          color='green'
          size={50}
          aria-label="Loading Spinner"
        />
      </div>      ) : (
        <>
      <TabBar />

      <div className="home-container" style={{backgroundImage: `url(${!backgroundImage ? 'https://images.unsplash.com/photo-1606787366850-de6330128bfc?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' : backgroundImage})`,
                                              backgroundSize: 'cover',
                                              backgroundRepeat: 'no-repeat',
                                              backgroundPosition: 'center',
                                              height: '50vh',
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              padding: '40px 10px'
                                                }}>
        <button className="button-37" onClick={() => history.push('/home')}>
          {t('exploreLocalText')}
        </button>
      </div>

      <div className="explore-section">
      <i className="material-icons">local_dining</i>

        <div className="explore-content">
          <h2>{t('topChefsText')}</h2>
          <p>{t('topChefsSubText')}</p>
        </div>
        <div className="avatar-container card-deck">
          {Topchefs?.map((chef) => (
            <div key={chef.id} className="card mb-3 custom-home-card" onClick={() => history.push(`/vendor/${chef.id}`)}>
              <div className="row">
                <div className="col-5">
                <Avatar
                    src={chef.avatar}
                    size="150"
                    round={true}
                    className="mt-2 img-fluid custom-margin-av" // Add img-fluid class for responsiveness
                  />
                </div>
                <div className="col-6 mt-2">
                  <div className="d-flex flex-column">
                    <h5 className="me-3 text-center" style={{ fontSize: '1.5rem', color: '#333' }}>
                      {currentLanguage === 'en' ? chef.vendor_name : chef.chinese_vendor_name}
                    </h5>
                    <div className="d-flex flex-wrap justify-content-center">
                      {chef?.category?.map((cat, index) => (
                        <p key={index} className="me-1" style={{ fontSize: '1rem', color: '#555' }}>
                          {cat},
                        </p>
                      ))}
                    </div>
                    <p className="description-home text-center" style={{ fontSize: '1rem', color: '#777' }}>
                      {currentLanguage === 'en' ? chef.description : chef.chinese_description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div onClick={() => history.push(`/home`)} className="explore-more text-center">
            <p style={{ fontSize: '1rem', color: '#13aa52' }}>{t('exploreMoreChefText')}</p>
          </div>
        </div>
      </div>

      <div className="explore-section">
      <i className="material-icons">health_and_safety</i>
      <div className="explore-content">
        <h2>{t('foodSafetyTitle')}</h2>
        <p>{t('foodSafetySubtitle')}</p>
        <div className="avatar-container">
          <div className="row">
            <div className="col-md-3">
              {/* Image on the left */}
              <img
                src="https://www.vdh.virginia.gov/content/uploads/sites/83/2022/06/4785872-300x300.png"
                alt="Food Safety"
                className="img-fluid"
                />
            </div>
            <div className="col-md-9">
              {/* Content explaining food safety on the right */}
              <p>{t('foodSafetyDescription')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div className="explore-section">
        <i className="material-icons">question_mark</i>
        <div className="explore-content">
          <h2>{t('questionTitle')}</h2>
          <p>{t('questionSubtitle')}</p>
          <div className="interactive-faq">
            {faqData.map((faqItem, index) => (
              <div key={index} className="faq-item">
                <div
                  className={`question ${expandedIndex === index ? 'expanded' : ''}`}
                  onClick={() => handleToggleAnswer(index)}
                  >
                  {faqItem.question}
                  {expandedIndex === index ? <FaChevronUp /> : <FaChevronDown />}
                </div>
                {expandedIndex === index && (
                  <div className="answer">
                    <p>{faqItem.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>


      <Footer />
      </>
      )}
    </div>
  );
}








const englishFaqData = [
  {
    "id": 1,
    "question": "How do I find chefs in my area?",
    "answer": "You can use our filter feature to find chefs based on your location. Simply enter your city or choose a category to discover talented chefs near you."
  },
  {
    "id": 2,
    "question": "What types of cuisines do your chefs specialize in?",
    "answer": "Our chefs have diverse expertise, ranging from traditional cuisines to modern fusion. You can explore their profiles to see their specialties and menus."
  },
  {
    "id": 4,
    "question": "Are the chefs certified for food safety?",
    "answer": "Yes, all our chefs are food safety certified. We prioritize the safety and well-being of our customers, and our chefs adhere to the highest standards of hygiene and quality."
  }
]

const chineseFaqData = [
      {
        "id": 1,
        "question": "如何在我的区域找到厨师？",
        "answer": "您可以使用我们的过滤功能根据您的位置查找厨师。只需输入您的城市或选择一个类别，即可发现您附近的才华横溢的厨师。"
      },
      {
        "id": 2,
        "question": "您的厨师擅长哪些类型的菜肴？",
        "answer": "我们的厨师具有多样化的专业知识，从传统菜肴到现代融合都涵盖了。您可以浏览他们的个人资料以了解他们的专长和菜单。"
      },
      {
        "id": 4,
        "question": "厨师是否具有食品安全认证？",
        "answer": "是的，我们所有的厨师都经过食品安全认证。我们优先考虑顾客的安全和福祉，我们的厨师遵循最高的卫生和质量标准。"
      }
    
  ]

export default Home;
